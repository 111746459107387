import React, {useContext} from "react";
import { RouterState, RouterStore } from "mobx-state-router";
import async from "../../components/Async";
import { ModalState } from "../../components/elements/modal/GenericModal";
import { Modal2State } from "../../components/elements/modal2/GenericModal2";
import { StatusState } from "../../components/configs/status/StatusState";
import CreateCode from "../../components/codes/create/CreateCodeDomain";
import QrPreviewState from "../../components/qr/qrpreview/QrPreviewState";
import { EditCodeState } from "../../components/codes/edit/EditCodeState";
import { CodeModelList } from "../../models/codes/Code";
import { GenericToastState } from "../../components/elements/toast/GenericToastState";
import CodeListState from "../../components/codes/list/CodeListState";
import { buildColumns } from "../../components/codes/list/CodeListView";
import IosCreate from "react-ionicons/lib/IosCreate";
import IosPaper from "react-ionicons/lib/IosPaper"
import {getEnv, resolveIdentifier, types} from "mobx-state-tree";
import {UtmModalState} from "../../components/utms/modal/UtmModalState";
import {RuleSetModalState} from "../../components/configs/rulesets/modal/RuleSetModalState";
import {CreateRuleSetState} from "../../components/configs/rulesets/create/CreateRuleSetState";
import {TreeNode} from "../../components/configs/rulesets/create/CreateRuleForm";
import {UtmSelectState} from "../../components/elements/utms/UtmSelectState";
import {Utm} from "../../models/utms/UtmModel";
import {TagModel} from "../../models/tags/TagModel";
import {GroupSelectState} from "../../components/elements/groups/GroupSelectState";
import DesignerState from "../../components/qr/designer/DesignerState";
import ColorState from "../../components/qr/designer/color/ColorState";
import EyesAndModulesState from "../../components/qr/designer/eyesAndModules/EyesAndModulesState";
import LogoState from "../../components/qr/designer/logo/LogoState";
import FrameAndCaptionsState from "../../components/qr/designer/frameAndCaption/FrameAndCaptionsState";
import CaptionState from "../../components/qr/designer/frameAndCaption/CaptionState";
import {v4 as uuidv4} from 'uuid';
import ImageAndCharacteristicsState
  from "../../components/qr/designer/imageAndCharacteristics/ImageAndCharacteristicsState";
import {DesignerModalState} from "../../components/qr/modal/DesignerModalState";
import {ParameterSetModalState} from "../../components/configs/parametersets/modal/ParameterSetModalState";
import ParameterStore from "../../stores/domain/ParameterStore";
import CodeParamsStore from "../../stores/domain/parameters/CodeParamsStore";
import ScanParamsStore from "../../stores/domain/parameters/ScanParamsStore";
import {CodeParamsTypeSelectState} from "../../components/elements/parameters/codeType/CodeParamsTypeSelectState";
import {ScanParamsTypeSelectState} from "../../components/elements/parameters/scanType/ScanParamsTypeSelectState";
import CountParamsStore from "../../stores/domain/parameters/CountParamsStore";
import PremiumParamsStore from "../../stores/domain/parameters/PremiumParamsStore";
import CustomParamsStore from "../../stores/domain/parameters/CustomParamsStore";
import {CountParamsTypeSelectState} from "../../components/elements/parameters/countType/CountParamsTypeSelectState";
import {
  PremiumParamsTypeSelectState
} from "../../components/elements/parameters/premiumType/PremiumParamsTypeSelectState";
import {CustomParamsTypeSelectState} from "../../components/elements/parameters/customType/CustomParamsTypeSelectState";
import Parameters from "../../models/Parameters";
import {BehaviorModalState} from "../../components/elements/behaviorModal/BehaviorModalState";
import {QrDownloadState} from "../../components/qr/qrpreview/qrDownload/QrDownloadState";
import {codeBehavior} from "../../utils/constants";
import {ExtendedDataSettingModalState} from "../../components/extendedData/modal/ExtendedDataSettingModalState";
import {checkUserPermissions} from "../../utils/requiredRolesCheck";


// Page Async Reference, for lazy loading in UI
const CreateCodePage = async(() => import("../../pages/codes/create/Create"));
const CodesPage = async(() => import("../../pages/codes"));
const EditCodePage = async(() => import("../../pages/codes/edit/Edit"));
const DeleteCodePage = async(() => import("../../components/codes/delete/DeleteView"));


export const viewMap = {
  createCode: <CreateCodePage/>,
  deleteCode: <DeleteCodePage/>,
  editCode: <EditCodePage/>,
  codes: <CodesPage/>,
}

export const routeDefinitions = [
  {
    path: "createCode", //"/codes/new",
    name: "Create",
    header: "Codes",
    icon: IosCreate,
    component: CreateCodePage,
    children: null
  },
  {
    path: "codes",
    name: "Manage",
    icon: IosPaper,
    component: CodesPage,
    children: null
  }
];


export const routeTransitions = [
  {
    name: 'createCode',
    pattern: '/codes/new',
    beforeEnter: checkUserPermissions,
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const BaseConfig = {
        "dataShape": 0, "imageFormat": "png",
        "colorMode": "rgb", "quietZone": 4,
        "errorCorrection": "M", "size": 1,
        "resolution": 1200,
      }
      let {queryParams} = toState;
      const {rootStore} = routerStore.options;
      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const dimensionSelectViewState = rootStore.rootUiStore.dimensionSelectViewState;
      const codeTypeSelectState = rootStore.rootUiStore.codeTypeSelectStore;
      const publishDomainSelectStore = rootStore.rootUiStore.publishDomainSelectStore;
      const pageTemplateSelectStore = rootStore.rootUiStore.pageTemplateSelectStore

      if(queryParams.codeType){
        const codeType = codeTypeSelectState.store.codeTypes.find((codeType) => codeType.name === queryParams.codeType)
        if (codeType) {
          codeTypeSelectState.select(codeType);
          groupSelectState.selectGroup(groupSelectState.groupStore?.groups[0]);
        }
      }

      publishDomainSelectStore.refresh(groupSelectState.groupStore?.groups[0]);
      //groupSelectState.clearSelected();
      // tagSelectStore?.tagStore?.init(); //reset tags
      // tagSelectStore?.setSelectedTags([]); //need to reset tags when on new page due to reuse domain store
      const utmSelectState = rootStore.rootUiStore.utmSelectState;
      const parameterSetSelectState = rootStore.rootUiStore.parameterSetSelectState;
      await parameterSetSelectState.store.getParameterSets();

      const codeParamsStore = CodeParamsStore.create({
        storeUuid: "codeParamsStore"
      })
      const scanParamsStore = ScanParamsStore.create({
        storeUuid: "scanParamsStore"
      })
      const countParamsStore = CountParamsStore.create({
        storeUuid: "countParamsStore"
      })
      const premiumParamsStore = PremiumParamsStore.create({
        storeUuid: "premiumParamsStore"
      })
      const customParamsStore = CustomParamsStore.create({
        storeUuid: "customParamsStore"
      })
      await rootStore.rootUiStore.attachPageState('codeParamsStore', codeParamsStore)
      await rootStore.rootUiStore.attachPageState('scanParamsStore', scanParamsStore)
      await rootStore.rootUiStore.attachPageState('countParamsStore', countParamsStore)
      await rootStore.rootUiStore.attachPageState('premiumParamsStore', premiumParamsStore)
      await rootStore.rootUiStore.attachPageState('customParamsStore', customParamsStore)




      dimensionSelectViewState?.clearSelections()
      dimensionSelectViewState?.setDefaults()
      const modalStateCreateCode = ModalState.create({});
      const statusViewState = StatusState.create(({title:"Status"}))
      const ruleSetSelectState = rootStore.rootUiStore.ruleSetSelectState;
      const ruleTypeSelectState = rootStore.rootUiStore.ruleTypeSelectState;
      ruleSetSelectState?.store?.clearSearchParams() // to reset ruleSets store same problem above for tags
      ruleSetSelectState?.store?.init({}) // reset ruleSets
      const rootNode = TreeNode.create({
        nodeUuid: '4',
        parent: null,
        order: 0,
        children: [],
        showIndent: false,
        showUnindent: false,
        position: '0',
        volatileRuleTypeStore: ruleTypeSelectState.store
      })
        const createRuleSetState = CreateRuleSetState.create({
          isModalUi:true,
          ruleTypeSelectState:ruleTypeSelectState,
          rootNode: rootNode
        })
      const ruleSetModalState = RuleSetModalState.create({
        ruleSetSelectState:ruleSetSelectState,
        createRuleSetState:createRuleSetState

      })
      const utmModalState = UtmModalState.create({
        utmSelectState:utmSelectState,
        groupSelectStore:groupSelectState,
        utm:Utm.create({uuid:"modalUtm"})
      })
      const extendedDataStore = rootStore.domainStore.extendedDataStore;
      await extendedDataStore.init();

      const extendedDataSetSelectStore = rootStore.rootUiStore.extendedDataSetSelectStore;
      extendedDataSetSelectStore.store.init();

      const extendedDataSelectStore = rootStore.rootUiStore.extendedDataSelectStore;
      extendedDataSelectStore.store.init();

      const extendedDataSettingModalState = ExtendedDataSettingModalState.create({
        extendedDataSetSelectStore: extendedDataSetSelectStore,
        extendedDataSelectStore: extendedDataSelectStore
      });

      const parameterSetModalState =  ParameterSetModalState.create({
        groupSelectStore: groupSelectState,
        parameterSetSelectState: parameterSetSelectState,
        parameterStore: ParameterStore.create({
          storeUuid: "parameterStore"
        }),
        codeParamsTypeSelectState: CodeParamsTypeSelectState.create({
          selectStateUuid: "codeParamsTypeSelectState",
          store: rootStore.rootUiStore.codeParamsStore
        }),
        scanParamsTypeSelectState: ScanParamsTypeSelectState.create({
          selectStateUuid: "scanParamsTypeSelectState",
          store: rootStore.rootUiStore.scanParamsStore
        }),
        countParamsTypeSelectState: CountParamsTypeSelectState.create({
          selectStateUuid: "countParamsTypeSelectState",
          store: rootStore.rootUiStore.countParamsStore
        }),
        premiumParamsTypeSelectState: PremiumParamsTypeSelectState.create({
          selectStateUuid: "premiumParamsTypeSelectState",
          store: rootStore.rootUiStore.premiumParamsStore
        }),
        customParamsTypeSelectState: CustomParamsTypeSelectState.create({
          selectStateUuid: "customParamsTypeSelectState",
          store: rootStore.rootUiStore.customParamsStore
        }),
      });


      rootStore.rootUiStore.utmSelectState?.store.init({})
      const qrConfigSelectStore = rootStore.rootUiStore.qrConfigSelectStore
      const groupSelectStore = rootStore.rootUiStore.groupSelectStore
      const frameAndCaptionsStore = FrameAndCaptionsState.create({
        captionRows: [CaptionState.create({ id: uuidv4(), captionTextPosition: 'Top', captionFontSize: 50, captionContent: '', captionColor: '#000000' }) ],
        fillInColor: false,
      })

      const designerViewState = DesignerState.create({
        colorStore: ColorState.create({}),
        eyeStore: EyesAndModulesState.create({}),
        logoStore: LogoState.create({}),
        imageCharsStore: ImageAndCharacteristicsState.create({}),
        frameAndCaptionsStore: frameAndCaptionsStore,
        qrConfigSelectStore: qrConfigSelectStore,
        groupSelectStore:groupSelectStore,
        dashboardStore: rootStore.domainStore.dashboardStore
      })
      const designerModalState = DesignerModalState.create({
        designerViewStore: designerViewState,
        isEditCodePage: false,
        qrPreviewStore: QrPreviewState.create({}), // rootStore.rootUiStore.qrPreviewStore
        dashboardStore: rootStore.domainStore.dashboardStore
      })
      const qrPreviewStore = QrPreviewState.create({
        isEditCodePage: false,
        qrConfigSelectStore: qrConfigSelectStore,
        baseQRImageFormData: JSON.stringify(BaseConfig),
        modalStore: ModalState.create({}),
        qrDownloadStore: QrDownloadState.create({}),
        dashboardStore: rootStore.domainStore.dashboardStore
      })
      let createCodeViewState;
      const snapShot = (queryParams.q)?rootStore.rootUiStore?.globalCache[`clone:${queryParams.q}`]:null
      const snapShotImageUuid = snapShot?.images?.configs[0].uuid
      codeTypeSelectState.setEditMode(false);
      if (snapShot){ //hydrate form with snapShot for cloning a code
        groupSelectState?.selectGroup(snapShot?.owner?.groupUuid);
        codeTypeSelectState?.select(snapShot?.type);
        statusViewState?.setStatus(snapShot?.status);
        statusViewState?.setCloneForm(true);
        tagSelectStore?.setSelectedTags(snapShot?.tags?.flatMap((tag => tag.uuid.split('.')[1])));
        createCodeViewState = CreateCode.create({
          name: "",
          groupSelectStore: groupSelectState,
          dimensionSelectViewState: dimensionSelectViewState,
          tagSelectStore: tagSelectStore,
          codeTypeSelectStore: codeTypeSelectState,
          publishDomainSelectStore: publishDomainSelectStore,
          pageTemplateSelectStore: pageTemplateSelectStore,
          isExperienceDynamic: false,
          utmModalState: utmModalState,
          modalStore: modalStateCreateCode,
          statusViewState: statusViewState,
          ruleSetModalState: ruleSetModalState,
          designerModalState: designerModalState,
          parameterSetModalState: parameterSetModalState,
          extendedDataSettingModalState: extendedDataSettingModalState,
          behaviorModalState: BehaviorModalState.create({}),
          behavior: snapShot.behavior,
          rules: JSON.stringify(snapShot.rules),
          utm:Utm.create({uuid:"appliedUtm", ...snapShot?.utm}),
          parameters: Parameters.create({
            codeParamType: snapShot?.parameters?.codeParamType,
            scanParamType: snapShot?.parameters?.scanParamType,
            countParamType: snapShot?.parameters?.countParamType,
            premiumParamType: snapShot?.parameters?.premiumParamType,
            customParamType: snapShot?.parameters?.customParamType,
          })
        });
        createCodeViewState.setStatus(snapShot?.status);
        if (snapShotImageUuid) { // clone image
          const configStore = rootStore.rootUiStore.qrConfigSelectStore.configStore
          //fetch image qrConfig
          const imageQrConfig = await configStore.fetchImageConfigWithId(snapShotImageUuid)
          const data ={
            "qrPreview": imageQrConfig.image,
            "qrImageFormData": imageQrConfig.qrConfig,
            "owner": {"groupUuid":imageQrConfig.owner.groupUuid}
          }
          const clonedImageConfig = await getEnv(rootStore).imageConfigManager.applyToCode(data)
          const {uuid} = clonedImageConfig.data
          createCodeViewState?.handleImageUuidChange(uuid)
          const fetchedClone = await configStore.fetchImageConfigWithId(uuid)
          qrPreviewStore?.setQRImageFormData(fetchedClone?.qrConfig)
          qrConfigSelectStore.setSelectedConfig(fetchedClone)
        }
        // tagSelectStore?.tagStore?.init()
        createCodeViewState?.updateExperienceData(snapShot?.data)
      } else { // create new code
        tagSelectStore?.tagStore?.init(); //reset tags
        tagSelectStore?.setSelectedTags([]); //need to reset tags when on new page due to reuse domain store
        createCodeViewState = CreateCode.create({
          groupSelectStore: groupSelectState,
          dimensionSelectViewState: dimensionSelectViewState,
          tagSelectStore: tagSelectStore,
          codeTypeSelectStore: codeTypeSelectState,
          publishDomainSelectStore: publishDomainSelectStore,
          pageTemplateSelectStore: pageTemplateSelectStore,
          isExperienceDynamic: true, // <-- appconfig per tenant/account/user
          utmModalState: utmModalState,
          parameterSetModalState: parameterSetModalState,
          extendedDataSettingModalState: extendedDataSettingModalState,
          behaviorModalState: BehaviorModalState.create({}),
          behavior: codeBehavior.DYNAMIC,
          modalStore: modalStateCreateCode,
          statusViewState: statusViewState,
          ruleSetModalState: ruleSetModalState,
          designerModalState: designerModalState,
          utm:Utm.create({uuid:"appliedUtm"}),
          parameters: Parameters.create({})
        });

        const userRoles = rootStore?.userRoles;
        createCodeViewState.setDefaultStatusBasedOnRoles(userRoles);
        codeTypeSelectState.setFsm(null);
        groupSelectState?.selectGroup(groupSelectState?.groupStore?.groups[0].uuid);
        tagSelectStore?.tagStore?.setFilterOn(groupSelectState?.groupStore?.groups[0].uuid);
        if(queryParams.platform) {
          createCodeViewState?.updateExperienceData({platform: queryParams?.platform});
        }

      }
      //const qrConfigSelectStore = rootStore.rootUiStore.qrConfigSelectStore
      const defaultConfig =
        qrConfigSelectStore.configStore?.defaultConfiguration?.find(element => element.is_default === true)

      defaultConfig && qrConfigSelectStore.selectConfig(defaultConfig)
      !defaultConfig && !snapShotImageUuid && qrConfigSelectStore.setSelectedConfig("")
      createCodeViewState?.selectExperience(codeTypeSelectState?.selected,snapShot?snapShot.behavior:null)


      await rootStore.rootUiStore.attachPageState('qrPreviewStore', qrPreviewStore)
      await rootStore.rootUiStore.attachPageState('createCodeViewState', createCodeViewState)
      await createCodeViewState.parameterSetModalState.fetchAllParameters()
      if (snapShot) {
        createCodeViewState?.extendedDataSettingModalState?.setConsumer(createCodeViewState.selectExtendedDataSelection);
        extendedDataSettingModalState?.hydrateModal(snapShot);
      } else {
        await createCodeViewState?.hydrateExtendedDataBasedOnState();
        createCodeViewState?.registerUpdatesForExtendedData();
      }
      !snapShotImageUuid && qrConfigSelectStore.configStore.removeImageConfig()
      rootStore.rootUiStore.editCodeState &&  rootStore.rootUiStore.editCodeState.setUuid("") // <-- hack to remove carry over group selection from edit to create
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      console.log("exit", fromState)
      console.log("exit", toState)
      console.log("exit", routerStore)
      const qrConfigSelectStore = rootStore.rootUiStore.qrConfigSelectStore
      qrConfigSelectStore.configStore.removeImageConfig()
      const createCodeViewState = rootStore.rootUiStore.createCodeViewState
      createCodeViewState?.handleImageUuidChange(undefined)
      createCodeViewState?.unRegisterUpdatesForExtendedData();
      // rootStore.rootUiStore.detachPageState('qrPreviewStore');
      // rootStore.rootUiStore.attachPageState('createCodeViewState');
    }
  },
  {
    name: 'deleteCode',
    pattern: '/codes/delete/:id',
    beforeEnter: checkUserPermissions,
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {params} = toState
      const {rootStore} = routerStore.options;
    }
  },
  {
    name: 'editCode',
    pattern: '/codes/edit/:id',
    beforeEnter: checkUserPermissions,
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {params, queryParams: {isEditable: isEditableStr}} = toState
      const isEditable = isEditableStr !== 'false';
      const isView = isEditable !== undefined ? !isEditable : false
      const {rootStore} = routerStore.options;
      const BaseConfig = {
        "dataShape": 0, "imageFormat": "png",
        "colorMode": "rgb", "quietZone": 4,
        "errorCorrection": "M", "size": 1,
        "resolution": 1200,
      }

      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const codeTypeSelectState = rootStore.rootUiStore.codeTypeSelectStore;
      const pageTemplateSelectStore = rootStore.rootUiStore.pageTemplateSelectStore;
      const dimensionSelectViewState = rootStore.rootUiStore.dimensionSelectViewState;
      const parameterSetSelectState = rootStore.rootUiStore.parameterSetSelectState;
      await parameterSetSelectState.store.getParameterSets();

      const codeParamsStore = CodeParamsStore.create({
        storeUuid: "codeParamsStore"
      })
      const scanParamsStore = ScanParamsStore.create({
        storeUuid: "scanParamsStore"
      })
      const countParamsStore = CountParamsStore.create({
        storeUuid: "countParamsStore"
      })
      const premiumParamsStore = PremiumParamsStore.create({
        storeUuid: "premiumParamsStore"
      })
      const customParamsStore = CustomParamsStore.create({
        storeUuid: "customParamsStore"
      })
      await rootStore.rootUiStore.attachPageState('codeParamsStore', codeParamsStore)
      await rootStore.rootUiStore.attachPageState('scanParamsStore', scanParamsStore)
      await rootStore.rootUiStore.attachPageState('countParamsStore', countParamsStore)
      await rootStore.rootUiStore.attachPageState('premiumParamsStore', premiumParamsStore)
      await rootStore.rootUiStore.attachPageState('customParamsStore', customParamsStore)
      const qrConfigStore = rootStore.domainStore.qrConfigStore
      const qrConfigSelectStore = rootStore.rootUiStore.qrConfigSelectStore
      const modalStateEditCode = ModalState.create({});
      const statusViewState = StatusState.create(({title: "Status", isEditForm: true, isView: isView}))
      const utmModalState = UtmModalState.create({
        utmSelectState:rootStore.rootUiStore.utmSelectState,
        utm:Utm.create({uuid:"appliedUtm"}),
        isView: isView
      })
      // groupSelectState.selectGroup(groupTypeStore.groups[1])
      const parameterSetModalState =  ParameterSetModalState.create({
        groupSelectStore: groupSelectState,
        parameterSetSelectState: parameterSetSelectState,
        parameterStore: ParameterStore.create({
          storeUuid: "parameterStore"
        }),
        codeParamsTypeSelectState: CodeParamsTypeSelectState.create({
          selectStateUuid: "codeParamsTypeSelectState",
          store: rootStore.rootUiStore.codeParamsStore
        }),
        scanParamsTypeSelectState: ScanParamsTypeSelectState.create({
          selectStateUuid: "scanParamsTypeSelectState",
          store: rootStore.rootUiStore.scanParamsStore
        }),
        countParamsTypeSelectState: CountParamsTypeSelectState.create({
          selectStateUuid: "countParamsTypeSelectState",
          store: rootStore.rootUiStore.countParamsStore
        }),
        premiumParamsTypeSelectState: PremiumParamsTypeSelectState.create({
          selectStateUuid: "premiumParamsTypeSelectState",
          store: rootStore.rootUiStore.premiumParamsStore
        }),
        customParamsTypeSelectState: CustomParamsTypeSelectState.create({
          selectStateUuid: "customParamsTypeSelectState",
          store: rootStore.rootUiStore.customParamsStore
        }),
        isView: isView
      });


      const ruleSetSelectState = rootStore.rootUiStore.ruleSetSelectState;
      const ruleTypeSelectState = rootStore.rootUiStore.ruleTypeSelectState;
      const rootNode = TreeNode.create({
        nodeUuid: '3',
        parent: null,
        order: 0,
        children: [],
        showIndent: false,
        showUnindent: false,
        position: '0',
        volatileRuleTypeStore: ruleTypeSelectState.store
      })
      const createRuleSetState = CreateRuleSetState.create({
        isModalUi:true,
        ruleTypeSelectState:ruleTypeSelectState,
        rootNode: rootNode,
        isView: isView
      })
      const ruleSetModalState = RuleSetModalState.create({
        ruleSetSelectState:ruleSetSelectState,
        createRuleSetState:createRuleSetState,
        isView: isView
      })
      const frameAndCaptionsStore = FrameAndCaptionsState.create({
        captionRows: [CaptionState.create({ id: uuidv4(), captionTextPosition: 'Top', captionFontSize: 50, captionContent: '', captionColor: '#000000' }) ],
        fillInColor: false,
        isView: isView
      })

      const designerViewState = DesignerState.create({
        colorStore: ColorState.create({}),
        eyeStore: EyesAndModulesState.create({}),
        logoStore: LogoState.create({}),
        imageCharsStore: ImageAndCharacteristicsState.create({}),
        frameAndCaptionsStore: frameAndCaptionsStore,
        qrConfigSelectStore: qrConfigSelectStore,
        groupSelectStore:groupSelectState,
        isView: isView,
        dashboardStore: rootStore.domainStore.dashboardStore
      })
      const designerModalState = DesignerModalState.create({
        designerViewStore: designerViewState,
        isEditCodePage: true,
        qrPreviewStore: QrPreviewState.create({}), // rootStore.rootUiStore.qrPreviewStore
        isView: isView,
        dashboardStore: rootStore.domainStore.dashboardStore
      })

      const extendedDataStore = rootStore.domainStore.extendedDataStore;
      await extendedDataStore.init();

      const extendedDataSetSelectStore = rootStore.rootUiStore.extendedDataSetSelectStore;
      extendedDataSetSelectStore.store.init();

      const extendedDataSelectStore = rootStore.rootUiStore.extendedDataSelectStore;
      extendedDataSelectStore.store.init();

      const extendedDataSettingModalState = ExtendedDataSettingModalState.create({
        extendedDataSetSelectStore: extendedDataSetSelectStore,
        extendedDataSelectStore: extendedDataSelectStore,
        isView: isView
      });

      const editCodeState = EditCodeState.create({
        groupSelectStore: groupSelectState,
        tagSelectStore: tagSelectStore,
        pageTemplateSelectStore: pageTemplateSelectStore,
        dimensionSelectViewState: dimensionSelectViewState,
        codeTypeSelectStore: codeTypeSelectState,
        isExperienceDynamic: true,
        extendedDataSettingModalState: extendedDataSettingModalState,
        uuid: params.id,
        modalStore: modalStateEditCode,
        statusViewState: statusViewState,
        utmModalState: utmModalState,
        ruleSetModalState: ruleSetModalState,
        designerModalState: designerModalState,
        parameterSetModalState: parameterSetModalState,
        parameters: Parameters.create({}),
        isView: isView
      });
      const modalState = ModalState.create({});
      // const qrConfigSelectStore = rootStore.rootUiStore.qrConfigSelectStore
      const qrDownloadStore = QrDownloadState.create({});
      const qrPreviewStore = QrPreviewState.create({
        modalStore: modalState,
        qrConfigStore: qrConfigStore,
        qrConfigSelectStore: qrConfigSelectStore,
        baseQRImageFormData: JSON.stringify(BaseConfig),
        isEditCodePage: true,
        qrDownloadStore: qrDownloadStore,
        dashboardStore: rootStore.domainStore.dashboardStore
      })
      await rootStore.rootUiStore.attachPageState('qrPreviewStore', qrPreviewStore)
      await rootStore.rootUiStore.attachPageState('editCodeState', editCodeState)
      await editCodeState.parameterSetModalState.fetchAllParameters()
      await
      editCodeState?.setShortUrlListener(qrPreviewStore.setShortUrl)
      editCodeState?.setNameListener(qrPreviewStore.updateName);
      editCodeState?.setOnSelectChanges()
      const res = await editCodeState?.fetchWithID()

      const imageUuid = res?.images?.configs[0]?.uuid

      if (imageUuid) {
        editCodeState?.handleImageUuidChange(imageUuid)
        const imageResponse = await rootStore.domainStore.qrConfigStore?.fetchImageConfigWithId(imageUuid)
        qrPreviewStore?.setQRImageFormData(imageResponse?.qrConfig)
        qrConfigSelectStore.setSelectedConfig(imageResponse);
        // qrPreviewStore.selectQRConfig(imageResponse)
      }else{
        qrConfigSelectStore.setSelectedConfig("")
        qrPreviewStore.updateImage(qrPreviewStore.shortUrl)
      }
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      console.log("exit", fromState)
      console.log("exit", toState)
      console.log("exit", routerStore)
      const qrConfigSelectStore = rootStore.rootUiStore.qrConfigSelectStore
      const editCodeState = rootStore.rootUiStore.editCodeState
      editCodeState?.handleImageUuidChange("")
      qrConfigSelectStore.configStore.removeImageConfig()
    }
  },
  {
    name: 'codes',
    pattern: '/codes',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;

      const viewStateExisting = rootStore.rootUiStore.codeListViewState;

      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const codeFilterSelectState = rootStore.rootUiStore.codeFilterSelectState;
      const codeOwnerFilterSelectState = rootStore.rootUiStore.codeOwnerFilterSelectState;
      const dimensionSelectViewState = rootStore.rootUiStore.dimensionSelectViewState;
      const tagFilterSelectState = rootStore.rootUiStore.tagFilterSelectState;
      const groupFilterSelectState = rootStore.rootUiStore.groupFilterSelectState;
      const pageTemplateSelectStore = rootStore.rootUiStore.pageTemplateSelectStore;
      const qrtemplateSelectState = rootStore.rootUiStore.qrtemplateSelectStore;
      groupSelectState.selectGroup(undefined);
      tagSelectStore?.tagStore?.init(); //reset tags
      tagSelectStore?.setSelectedTags([]); //need to reset tags when on new page due to reuse domain store
      codeFilterSelectState.reset();
      codeOwnerFilterSelectState.reset();
      dimensionSelectViewState.reset();
      tagFilterSelectState.reset();
      groupFilterSelectState.reset();

      const codes = CodeModelList.create({});
      const toastState = GenericToastState.create({})
      const modalState = ModalState.create({});
      const modal2State = Modal2State.create({});
      const viewState = CodeListState.create({
        store: codes,
        modalStore: modalState,
        modal2Store: modal2State,
        toastStore: toastState,
        tagSelectStore: tagSelectStore,
        groupSelectStore: groupSelectState,
        pageTemplateSelectStore: pageTemplateSelectStore,
        codeFilterSelectState: codeFilterSelectState,
        codeOwnerFilterSelectState: codeOwnerFilterSelectState,
        dimensionSelectViewState: dimensionSelectViewState,
        qrtemplateSelectStore:qrtemplateSelectState,
        tagFilterSelectState: tagFilterSelectState,
        groupFilterSelectState: groupFilterSelectState
      });

      viewState.setColumns(buildColumns(routerStore, viewState))
      console.log("Attaching Page State : codeListViewState ")
      await rootStore.rootUiStore.attachPageState('codeListViewState', viewState)
      await viewState.fetchFilters();
      await viewState.refresh();
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      await rootStore.rootUiStore.codeListViewState.store.removeList()
      console.log("Detaching Page State : codeListViewState ")
      //rootStore.rootUiStore.detachPageState('codeListViewState')
    }
  }
]


import axios from "axios";

class AwsHttpClient {
	// only put csv for batch upload
	async put(url, data, config = {}) {
		try {
			const response = await axios.put(url, data, {
				headers: {
					"Content-Type": "text/csv",
				},
			});
			return response.data;
		} catch (e) {
			// console.log("e",e);
			// console.log("e.response.data",e.response.data);
			// console.log("e.response.status",e.response.status);
			// console.log("e.response.headers",e.response.headers);
			throw new Error(`${e.response.status} status. Current error message comes back as a html doctype`);
		}
	}
}

export default AwsHttpClient;

import { getType, resolveIdentifier, types } from 'mobx-state-tree'
import GroupStore from "../../../stores/domain/GroupStore";
import Group from "../../../models/Group";
import lodash from "lodash";

export const GroupSelectState = types.model('GroupSelectState',{
  selectStateUuid: types.maybe(types.identifier),
  groupStore: types.maybe(types.late(() => types.reference(GroupStore))),
  selectedGroup: types.maybe(types.safeReference(Group)),
  selectedGroups: types.optional(types.array(types.safeReference(Group)), [])
}).volatile(self => ({
  notifySelect: types.f,
  notifyMultiSelect: types.f,
})).actions((self)=>({
  searchGroup(txt){
    //self.groupOptions = self.groupStore.searchFor(txt)
  },
  setConsumer(f){
    self.notifySelect = f
  },
  setMultiSelectConsumer(f) {
    self.notifyMultiSelect = f
  },
  clearSelected() {
    self.selectedGroup = undefined
    self.selectedGroups = []
  },
  selectGroup(e) {
    console.log("#### select group", e)
    if(lodash.isArray(e)) {
      self.selectedGroups = e?.map(g => {
        return resolveIdentifier(Group, self, g?.uuid || g)
      })
      self.notifyMultiSelect(self.selectedGroups)
    } else if(e) {
      self.selectedGroup = resolveIdentifier(Group, self, e?.uuid || e)
      self.notifySelect(self.selectedGroup)
    }

    //to handle edge case wheere user deletes selected group
    if(e === null) {
      self.notifyMultiSelect([])
    }
  },
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
    self.notifySelect = (data) => {
      console.log("placeholder notifySelect")
    }
  },

})).views( (self) => ({
  groupOptionValues() {
    const { groups } = self.groupStore || [];
    return groups.slice().sort((a,b)=> a.label.localeCompare(b.label))
  },
  getFirstGroup() {
    // const { groups } = self.groupStore || {};
    // self.selectGroup(self.selectedGroup ? self.selectedGroup : groups[0])
    // return groups[0] || {}
    // // console.log("get first group")
    // // return self.selectedGroup?.toJSON() || null
    return self.selectedGroup
  }

}))

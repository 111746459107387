import { RouterState, RouterStore } from "mobx-state-router";
import { ModalState } from "../../components/elements/modal/GenericModal";
import QrPreviewState from "../../components/qr/qrpreview/QrPreviewState";
import ColorState from "../../components/qr/designer/color/ColorState";
import EyesAndModulesState from "../../components/qr/designer/eyesAndModules/EyesAndModulesState";
import LogoState from "../../components/qr/designer/logo/LogoState";
import ImageAndCharacteristicsState
  from "../../components/qr/designer/imageAndCharacteristics/ImageAndCharacteristicsState";
import DesignerState from "../../components/qr/designer/DesignerState";
import { GenericToastState } from "../../components/elements/toast/GenericToastState";
import { resolveIdentifier, types } from "mobx-state-tree";
import { QrConfigStore } from "../../stores/domain/QrConfigStore";
import { DesignsList } from "../../models/DesignsList";
import { DesignsListState } from "../../components/qr/qrList/DesignsListState";
import React from "react";
import async from "../../components/Async";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import FrameAndCaptionsState from "../../components/qr/designer/frameAndCaption/FrameAndCaptionsState";
import CaptionState from "src/components/qr/designer/frameAndCaption/CaptionState";
import {v4 as uuidv4} from 'uuid';
import { QrDownloadState } from "src/components/qr/qrpreview/qrDownload/QrDownloadState";
import {checkUserPermissions} from "../../utils/requiredRolesCheck";


const DesignerHomePage = async(() => import("../../pages/qr/designer/DesignerHome"));
const EditQRDesignHomePage = async(() => import("../../pages/qr/designer/EditQRDesignHome"));
const QrDesignsHomePage = async(()=>import("../../pages/qr/designs/QrDesignsHome"))


export const viewMap = {
  qrDesigner: <DesignerHomePage/>,
  editQrDesign: <EditQRDesignHomePage/>,
  qrDesigns: <QrDesignsHomePage />,

};
export const routeDefinitions = [
  {
    path: "qrDesigns",
    name: "Designer Templates",
    icon: faQrcode,
    component: QrDesignsHomePage,
    children: null,
    header: "QR Designer",
  }
];
export const routeTransitions = [
  {
    name: 'qrDesigner',
    pattern: '/qr/designer',
    beforeEnter: checkUserPermissions,
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      let {queryParams} = toState;
      const {rootStore} = routerStore.options;

      console.log(fromState)
      console.log(toState)

      const qrConfigSelectStore = rootStore.rootUiStore.qrConfigSelectStore
      const groupSelectStore = rootStore.rootUiStore.groupSelectStore
      const dimensionsMultiSelectState = rootStore.rootUiStore.dimensionsMultiSelectState
      const dashboardStore = rootStore.domainStore.dashboardStore
      groupSelectStore.selectGroup(groupSelectStore.groupStore?.groups[0]);
      dimensionsMultiSelectState.init();
      dimensionsMultiSelectState.setDefaults();

      const modalState = ModalState.create({});
      const createQRPreviewStore = QrPreviewState.create({
        qrConfigSelectStore: qrConfigSelectStore,
        modalStore: modalState,
        dashboardStore: dashboardStore
      })
      const ColorStore = ColorState.create({})
      const EyesAndModulesStore = EyesAndModulesState.create({})
      const LogoStore = LogoState.create({})
      const imageCharsStore = ImageAndCharacteristicsState.create({})

      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const frameAndCaptionsStore = FrameAndCaptionsState.create({
        captionRows: [CaptionState.create({ id: uuidv4(), captionTextPosition: 'Top', captionFontSize: 50, captionContent: '', captionColor: '#000000' }) ],
        fillInColor: false,
      })

      let createDesignerState;
      const snapShot = (queryParams.q)?rootStore.rootUiStore?.globalCache[`clone-designer:${queryParams.q}`]:null
      if(snapShot) {
        groupSelectStore?.selectGroup(snapShot?.owner?.groupUuid);
        createQRPreviewStore.setQRImageFormData(snapShot.qrConfig);
        dimensionsMultiSelectState.init();
        dimensionsMultiSelectState.setDefaults();

        createDesignerState = DesignerState.create({
          qrConfigSelectStore: qrConfigSelectStore,
          colorStore: ColorStore,
          eyeStore: EyesAndModulesStore,
          logoStore: LogoStore,
          imageCharsStore: imageCharsStore,
          groupSelectStore: groupSelectStore,
          frameAndCaptionsStore: frameAndCaptionsStore,
          dimensionsMultiSelectStore: dimensionsMultiSelectState,
          tagSelectStore: tagSelectStore,
          dashboardStore: dashboardStore
        })
      } else {
        groupSelectStore.selectGroup(groupSelectStore.groupStore?.groups[0]);
        dimensionsMultiSelectState.init();
        dimensionsMultiSelectState.setDefaults();
        dimensionsMultiSelectState.getListOnGroup(groupSelectStore.groupStore?.groups[0]?.uuid);
        tagSelectStore?.tagStore?.init(); //reset tags
        tagSelectStore?.setSelectedTags([]);
        tagSelectStore?.tagStore?.setFilterOn(groupSelectStore.groupStore?.groups[0].uuid);

        createDesignerState = DesignerState.create({
          isTemplateForm: true,
          qrConfigSelectStore: qrConfigSelectStore,
          colorStore: ColorStore,
          eyeStore: EyesAndModulesStore,
          logoStore: LogoStore,
          imageCharsStore: imageCharsStore,
          groupSelectStore:groupSelectStore,
          frameAndCaptionsStore:frameAndCaptionsStore,
          dimensionsMultiSelectStore: dimensionsMultiSelectState,
          tagSelectStore: tagSelectStore,
          dashboardStore: dashboardStore
        })

      }

      await rootStore.rootUiStore.attachPageState('designerViewState', createDesignerState)
      await rootStore.rootUiStore.attachPageState('qrPreviewStore', createQRPreviewStore)
      snapShot && createDesignerState.hydrateEditComponent(snapShot, true, true);

    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      console.log("exit", fromState)
      console.log("exit", toState)
      console.log("exit", routerStore)
    }
  },
  {
    name: 'editQrDesign',
    pattern: '/qr/designer/edit/:uuid',
    beforeEnter: checkUserPermissions,
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {params, queryParams: {isEditable: isEditableStr}} = toState
      const isEditable = isEditableStr !== 'false';
      const isView = isEditable !== undefined ? !isEditable : false
      const {rootStore} = routerStore.options;
      console.log(fromState)
      console.log(toState)
      console.log(routerStore)
      const qrConfigSelectStore = rootStore.rootUiStore.qrConfigSelectStore
      const ColorStore = ColorState.create({})
      const EyesAndModulesStore = EyesAndModulesState.create({})
      const LogoStore = LogoState.create({})
      const frameAndCaptionsStore = FrameAndCaptionsState.create({})
      const ImageCharStore = ImageAndCharacteristicsState.create({})
      const groupSelectStore =rootStore.rootUiStore.groupSelectStore
      const dashboardStore = rootStore.domainStore.dashboardStore
      const dimensionsMultiSelectState = rootStore.rootUiStore.dimensionsMultiSelectState
      dimensionsMultiSelectState.init();
      dimensionsMultiSelectState.setDefaults();

      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      tagSelectStore?.tagStore?.init(); //reset tags
      //tagSelectStore?.setSelectedTags([]);

      const createDesignerState = DesignerState.create({
        isTemplateForm: true,
        colorStore: ColorStore,
        eyeStore: EyesAndModulesStore,
        logoStore: LogoStore,
        imageCharsStore: ImageCharStore,
        groupSelectStore:groupSelectStore,
        selectedConfigUUID: params.uuid,
        isEditForm: true,
        frameAndCaptionsStore:frameAndCaptionsStore,
        dimensionsMultiSelectStore: dimensionsMultiSelectState,
        tagSelectStore: tagSelectStore,
        qrConfigSelectStore: qrConfigSelectStore,
        isView: isView,
        dashboardStore: rootStore.domainStore.dashboardStore
      })

      //selectGroup(
      const createQRPreviewStore = rootStore.rootUiStore.qrPreviewStore
        ? rootStore.rootUiStore.qrPreviewStore
        : QrPreviewState.create({qrDownloadStore: QrDownloadState.create({})})
      createQRPreviewStore.setDashboard(dashboardStore)
      await rootStore.rootUiStore.attachPageState('designerViewState', createDesignerState)
      if (!rootStore.rootUiStore.qrPreviewStore) {
        await rootStore.rootUiStore.attachPageState('qrPreviewStore', createQRPreviewStore)
      }
      const res = await createDesignerState.fetchConfigWithID(params.uuid)
      await createQRPreviewStore.setQRImageFormData(res.qrConfig)

    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      console.log("exit", fromState)
      console.log("exit", toState)
      console.log("exit", routerStore)
    }
  },
  {
    name: 'qrDesigns',
    pattern: '/qr/designs',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {

      const {rootStore} = routerStore.options;
      console.log(fromState)
      console.log(toState)
      console.log(routerStore)
      await rootStore.rootUiStore.detachPageState('designsListState')

      const modalState = ModalState.create({});
      const toastState = GenericToastState.create({})
      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const codeOwnerFilterSelectState = rootStore.rootUiStore.codeOwnerFilterSelectState;
      const advFilterSelectState = rootStore.rootUiStore.advFilterSelectState;
      const dimensionSelectViewState = rootStore.rootUiStore.dimensionSelectViewState;
      const tagFilterSelectState = rootStore.rootUiStore.tagFilterSelectState;
      const groupFilterSelectState = rootStore.rootUiStore.groupFilterSelectState;
      codeOwnerFilterSelectState.reset();
      advFilterSelectState.reset();
      dimensionSelectViewState.reset();
      tagFilterSelectState.reset();
      groupFilterSelectState.reset();

      const configStore = resolveIdentifier(QrConfigStore, rootStore, 'qrcs');

      const designsList = DesignsList.create({
        configStore: configStore

      })

      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      tagSelectStore?.tagStore?.init(); //reset tags
      tagSelectStore?.unSetConsumer();
      tagSelectStore?.setSelectedTags([]);


      const designsListStore = DesignsListState.create({
        store: designsList,
        modalStore: modalState,
        toastStore: toastState,
        groupSelectStore: groupSelectState,
        codeOwnerFilterSelectState: codeOwnerFilterSelectState,
        advFilterSelectState: advFilterSelectState,
        dimensionSelectViewState: dimensionSelectViewState,
        tagSelectStore: tagSelectStore,
        tagFilterSelectState: tagFilterSelectState,
        groupFilterSelectState: groupFilterSelectState
      })

      await rootStore.rootUiStore.attachPageState('designsListState', designsListStore)
      await designsListStore.getFilters();
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      await rootStore.rootUiStore.designsListState.store.removeListOnExit()
      console.log("exit", fromState)
      console.log("exit", toState)
      console.log("exit", routerStore)
    }
  },
]
